





























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { TimelineItem } from '@/types/cms/components/';
import { DataTableHeader } from 'vuetify/types/';
import { CloneHelper } from '@/utils/clonehelper';

/**
 * HistoryTimelineList component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'history-timeline-form-dialog': () => import('./HistoryTimelineFormDialog.vue')
    }
})
export default class HistoryTimelineList extends Vue {
    // Prop of type boolean that defined whether the items are editable or not
    @Prop(Boolean) readonly editable: boolean | undefined;

    // array with all timeline items
    @Prop({ type: Array as () => Array<TimelineItem> }) readonly timelineItems: Array<TimelineItem> | undefined;

    // search text
    private searchText = '';

    // the item of the new/edited timeline
    private editedTimelineItem: TimelineItem = {
        id: -1,
        content: '',
        oppositeContent: ''
    };

    // Default timeline item values
    private defaultTimelineItem: TimelineItem = {
        id: 0,
        content: '',
        oppositeContent: ''
    }

    // The index of the edited timeline (-1 = new timeline)
    private editedTimelineIndex = -1;

    // Boolean that defines, whether the FormDialog will be displayed or not
    private showFormDialog = false;

    // Mode of the current form (CRUD string)
    private formDialogMode = 'create';

    /**
     * @returns Array with all table headers
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get tableHeaders(): Array<DataTableHeader> {
        const headers: Array<DataTableHeader> = [
            {
                text: this.$t('table.headers.id').toString(),
                value: 'id'
            },
            {
                text: this.$t('table.headers.oppositeContent').toString(),
                value: 'oppositeContent'
            },
            {
                text: this.$t('table.headers.content').toString(),
                value: 'content'
            }
        ];

        if (this.editable) {
            headers.push({
                text: this.$t('table.headers.actions').toString(),
                value: 'actions'
            });
        }
        
        return headers;
    }

    /**
     * Opens the TimelineFormDialog to create a new timeline item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private addTimeline() {
        // get max id
        const timelineItemId = (this.timelineItems && this.timelineItems.length >= 1) ? this.timelineItems[this.timelineItems.length -1].id +1 : 0;

        this.editedTimelineItem = CloneHelper.deepCloneObject({...this.defaultTimelineItem, id: timelineItemId });
        this.editedTimelineIndex = -1;
        this.formDialogMode = 'create';
        this.showFormDialog = true;
    }

    /**
     * Opens the TimelineFormDialog to edit the given timeline element
     * 
     * @param timelineItem timeline item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private editTimeline(timelineItem: TimelineItem) {
        if (this.timelineItems) {
            this.editedTimelineItem = CloneHelper.deepCloneObject(timelineItem);
            this.editedTimelineIndex = this.timelineItems.indexOf(timelineItem);
            this.formDialogMode = 'update';
            this.showFormDialog = true;
        }
    }

    /**
     * Deletes the given timeline item
     * 
     * @param timelineItem timeline item
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private deleteTimeline(timelineItem: TimelineItem) {
        if (this.timelineItems) {
            this.editedTimelineItem = CloneHelper.deepCloneObject(timelineItem);
            this.editedTimelineIndex = this.timelineItems.indexOf(timelineItem);
            this.formDialogMode = 'delete';
            this.showFormDialog = true;
        }
    }

    /**
     * Updates the edited item in the data table
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private updateEditedTimelineItem(formDialogMode: string, timelineItemIndex: number, timelineItem: TimelineItem) {
        if (this.timelineItems) {
            if (formDialogMode == 'create') {
                this.timelineItems.push(timelineItem);
            }
            else if (formDialogMode == 'update') {
                Object.assign(this.timelineItems[timelineItemIndex], timelineItem);
            }
            else if (formDialogMode == 'delete') {
                this.timelineItems.splice(timelineItemIndex, 1);
            }

            this.showFormDialog = false;
        }
    }
}
